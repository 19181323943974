// *******************
// Screen resolutions
// *******************

// Mobile
$mobile_xs: 374px;

$mobile_sm_min: 375px;
$mobile_sm_max: 639px;

$mobile_md_min: 640px;
$mobile_md_max: 767px;

// Tablet
$tablet_p_min: 768px;
$tablet_p_max: 1024px;

$tablet_l_min: 1024px;
$tablet_l_max: 1199px;

// Laptop
$desktop_all: 1200px;
$desktop_retina_min: 1440px;
$desktop_wide : 1600px;
$desktop_fhd: 1920px;




// *******************
// Screen resolutions
// *******************

// ***************
// Moblie All
// **************
// Both Potrait and Landscape
@mixin mobile_all {
  @media only screen and (max-width: $mobile_md_max) {
    @content;
  }
}

// ***************
// Moblie XS
// **************
// Both Potrait and Landscape
@mixin mobile_xs {
  @media only screen and (max-width: $mobile_xs) {
    @content;
  }
}


// ***************
// Moblie Small
// **************
// Both Potrait and Landscape
@mixin mobile_sm {
  @media only screen and (min-width: $mobile_sm_min) and (max-width: $mobile_sm_max) {
    @content;
  }
}


// ***************
// Moblie Medium
// **************
// Both Potrait and Landscape
@mixin mobile_md {
  @media only screen and (min-width: $mobile_md_min) and (max-width: $mobile_md_max) {
    @content;
  }
}



// Only Landscape Mobile
@mixin mobile_landscape {
  @media only screen and (min-width: $mobile_md_min) and (max-height: $mobile_md_max) and (orientation: landscape) {
    @content;
  }
}


// ****************
// Tablet
// ****************
// Both Potrait and Landscape
@mixin tablet_potrait {
  @media only screen and (min-width: $tablet_p_min) and (max-width: $tablet_p_max) {
    @content;
  }
}

// Only Landscape
@mixin tablet_landscape {
  @media only screen and (min-width: $tablet_l_min)  and (max-width: $tablet_l_max) and (orientation: landscape) {
    @content;
  }
}

@mixin max_tablet{
  @media only screen and (max-width: $tablet_l_max) {
    @content;
  }
}

// ****************
// Desktop All
// ****************
@mixin desktop_all {
  @media only screen and (min-width: $desktop_all) {
    @content;
  }
}

// ****************
// Desktop Medium
// ****************
@mixin desktop_retina_min {
  @media only screen and (min-width: $desktop_retina_min) {
    @content;
  }
}

// ****************
// Desktop Full HD
// ****************

@mixin desktop_fhd {
  @media only screen and (min-width: $desktop_fhd) {
    @content;
  }
}

// ****************
// Desktop Wide to FHD
// ****************

@mixin desktop_wide_fhd{
  @media only screen and (min-width: $desktop_wide) and (max-width: $desktop_fhd) {
    @content;
  }
}



// *******************
// *******************
// Browser Compatibility
// *******************
// *******************

// Internet Explorer
@mixin internet_explorer {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// Microsoft Edge
@mixin edge {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}

// Firefox
@mixin firefox {
  @-moz-document url-prefix() {
    @content;
  }
}

// Chrome [Old]
@mixin chrome_old {
  @media screen and (-webkit-min-device-pixel-ratio:0){
    @content;
  }
}

// Chrome [Latest]
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    @content;
  }
}


// Safari [old]
@mixin safari_old {
  @media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) {
      @content;
  }
}

// Safari
@mixin safari {
  @media not all and (min-resolution:.001dpcm) {
      @content;
  }
}
